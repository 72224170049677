document.addEventListener("DOMContentLoaded", e => {
    let menuBtns = document.querySelectorAll('.menu-toggle');
    if(menuBtns.length)
    {
        menuBtns.forEach(b => {
            b.addEventListener('click', () => {
                document.body.classList.toggle('menu-opened')
            })
        })
    }


});