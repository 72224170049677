var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v-form" }, [
    _c("div", { staticClass: "v-form__title" }, [_vm._v("Задать вопрос")]),
    _vm._v(" "),
    _vm.state.notice
      ? _c("div", { staticClass: "v-form__notice" }, [
          _vm._v(_vm._s(_vm.notice.ok))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "v-form__fields",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.send($event)
          }
        }
      },
      [
        _c("div", { staticClass: "v-form__box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "v-form__input",
            attrs: { type: "text", placeholder: "Имя", required: true },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-form__box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "v-form__input",
            attrs: { type: "text", placeholder: "Email" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-form__box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone"
              }
            ],
            staticClass: "v-form__input",
            attrs: { type: "text", placeholder: "Телефон", required: true },
            domProps: { value: _vm.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-form__box" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message"
              }
            ],
            staticClass: "v-form__input",
            attrs: { placeholder: "Ваш вопрос", required: true },
            domProps: { value: _vm.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v-form__box" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-blue v-form__btn",
              attrs: { type: "submit", disabled: _vm.loading }
            },
            [_vm._v("Оптравить")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }