<template>
    <div class="v-form">
	    <div class="v-form__title">Задать вопрос</div>

	    <div class="v-form__notice" v-if="state.notice">{{notice.ok}}</div>

	    <form class="v-form__fields" @submit.prevent="send">
		    <div class="v-form__box">
			    <input type="text" v-model="name" placeholder="Имя" :required="true" class="v-form__input">
		    </div>
		    <div class="v-form__box">
			    <input type="text" v-model="email" placeholder="Email" class="v-form__input">

		    </div>
		    <div class="v-form__box">
			    <input type="text" v-model="phone" placeholder="Телефон" :required="true" class="v-form__input">

		    </div>
		    <div class="v-form__box">
			    <textarea v-model="message" placeholder="Ваш вопрос" class="v-form__input" :required="true"></textarea>
		    </div>
		    <div class="v-form__box">
			    <button type="submit" :disabled="loading" class="btn btn-blue v-form__btn">Оптравить</button>
		    </div>

	    </form>

    </div>

</template>

<script>
    export default {
        name: "feedback",
        data(){
            return{
                name: null,
                phone: null,
                email: null,
                message: null,
                csrf: document.head.querySelector('meta[name="csrf-token"]').content,
                loading: false,
				notice: {
                	ok:'Ваше сообщение отправлено'
				},
	            state:{
                	notice:false,
	            }
            }
        },
        methods:{
            send(){

				this.loading = true;

				let mailData = {
					name: this.name,
					email: this.email,
					phone: this.phone,
					message: this.message,
					csrf: this.csrf
				};

				Axios.post('/feedback',mailData).then(result => {
					this.state.notice = true;

					setTimeout(e => {
						location.href = location.href
						this.state.notice = false
					}, 3000)
				})

            },
        }
    }
</script>

<style scoped>
    .contact-form{
        max-width: 400px;
    }
    .contact-form .row.text-row{
        font-size: 1.2em;
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
    }
    .contact-form .row{
        margin-bottom: 10px;
    }
    .contact-form .button-row{
        margin-top: 50px;
        text-align: center;
    }
    .text-input{
        width: 100%;
        height: 40px;
        padding-left: 8px;
        padding-right: 8px;
        border: 1px solid black;
    }
    .text-area{
        width: 100%;
        height: 200px;
        padding-top: 12px;
        padding-left: 8px;
        padding-right: 8px;
        border: 1px solid black;
    }
</style>