import Vue from 'vue';
import Axios from 'axios';
import Scroll from './scroll';


import Feedback from '../vue-components/Feedback';
import Popup from '../vue-components/Popup';

import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.css';


window.GLightbox = GLightbox;
window.Axios = Axios;
document.scroll = Scroll;

Vue.component('feedback',Feedback);
Vue.component('popup',Popup);

const App = new Vue({
	el: '#app',
	data:{
		url:''
	}
});

window.App = App;

document.addEventListener("DOMContentLoaded", function(event) {
    GLightbox();

	document.querySelectorAll('[data-popup]').forEach(button => button.addEventListener('click', () => {
		let modalName = button.getAttribute('data-popup');

		if(modalName && window.popups[modalName]) window.popups[modalName].open(button);
	}))
});

require('./frontend');